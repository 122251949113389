import * as React from "react";

import { Layout } from "@components/Layout";
import { Container } from "@components/Layout/Container";
import { heroAtom, seoAtom } from "@/store";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { Helmet } from "react-helmet";
import { useHydrateAtoms } from "jotai/utils";

const NotFoundPage = (): JSX.Element => {
  useHydrateAtoms([
    [heroAtom, undefined],
    [seoAtom, { title: "404: Not found" }],
  ])
  return (
    <>
      <Helmet>
        <title> Prereleased | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <Title>Prereleased Article</Title>
          </Header>
          <p className="min-h-[25vh]">
            We're Sorry! Prereleased article
          </p>
        </Container>
      </Layout>
    </>
  );
};

export default NotFoundPage;
